export const useGetTwicUrlBackground = (image: string) => {
  const config = useRuntimeConfig()
  const wealthTwicLink = ref('')

  if (config.public.env === 'development' || config.public.env === 'staging') {
    wealthTwicLink.value = 'wealthLocal/'
  } else if (config.public.env === 'preprod') {
    wealthTwicLink.value = 'wealthPreprod/'
  } else {
    wealthTwicLink.value = ''
  }

  return `url('media:/${wealthTwicLink.value}${image.data.attributes.hash}${image.data.attributes.ext}')`
}
